@font-face {
  font-family: "pixel";
  src: url("ipix.ttf");
}

.puppy-btn {
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  display: inline-block; /* Ensures the image sits within the button */
}

.AdventCalendar {
  height: 100vh;
  background-image: url("ChristmasBG.png");
  background-size: cover; /* <------ */
  background-repeat: no-repeat;
  background-position: center center;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .AdventCalendar {
    background-image: url("ChristmasBG_phone.png");
  }
}

body {
  height: 100vh;
  margin-left: auto;
  margin-right: auto;
}

.Puppy {
  height: 30vh;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.Paw {
  height: 10vh;
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.puppy-toast {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
  display: none;
}

.toast-text {
  width: 100%;
  white-space: normal; /* Allow text wrapping */
  overflow: hidden; /* Ensure no overflow during typing */
  text-align: left;
  line-height: 1.3em;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .puppy-toast {
    top: 35%;
    left: 45%;
  }

  .Puppy {
    height: 30vh;
    position: absolute;
    top: 63%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .Paw {
    height: 15vh;
    position: absolute;
    top: 78%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.puppy-toast.visible {
  position: absolute;
  opacity: 1;
  display: block;
}

.puppy-toast.hidden {
  position: absolute;
  opacity: 0;
  display: none;
}

.bubble {
  position: relative;
  display: inline-block;
  margin: 20px;
  text-align: center;
  font-size: 16px;
  line-height: 1.3em;
  background-color: #fff;
  color: #000;
  padding: 12px;
  box-shadow: 0 -4px #fff, 0 -8px #000, 4px 0 #fff, 4px -4px #000, 8px 0 #000,
    0 4px #fff, 0 8px #000, -4px 0 #fff, -4px 4px #000, -8px 0 #000,
    -4px -4px #000, 4px 4px #000;
  box-sizing: border-box;
  width: 200px;
}
.bubble::after {
  content: "";
  display: block;
  position: absolute;
  box-sizing: border-box;
}
.bubble.shadow {
  box-shadow: 0 -4px #fff, 0 -8px #000, 4px 0 #fff, 4px -4px #000, 8px 0 #000,
    0 4px #fff, 0 8px #000, -4px 0 #fff, -4px 4px #000, -8px 0 #000,
    -4px -4px #000, 4px 4px #000, 4px 12px rgba(0, 0, 0, 0.1),
    12px 4px rgba(0, 0, 0, 0.1), 8px 8px rgba(0, 0, 0, 0.1);
}
.bubble.mini {
  width: 110px;
  font-size: 16px;
  padding: 4px;
  font-family: "pixel";
}
.bubble.medium {
  width: 340px;
  font-family: "pixel";
  font-size: 19px;
}
.bubble.large {
  width: 560px;
  font-size: 24px;
  text-align: left;
  font-family: "pixel";
}
.bubble.grow {
  width: initial;
}
.bubble.top::after {
  height: 4px;
  width: 4px;
  top: -8px;
  left: 32px;
  box-shadow: 0 -4px #000, 0 -8px #000, 0 -12px #000, 0 -16px #000,
    -4px -12px #000, -8px -8px #000, -12px -4px #000, -4px -4px #fff,
    -8px -4px #fff, -4px -8px #fff, -4px 0 #fff, -8px 0 #fff, -12px 0 #fff;
}
.bubble.right::after {
  height: 4px;
  width: 4px;
  top: 84px;
  right: -8px;
  background: white;
  box-shadow: 4px -4px #fff, 4px 0 #fff, 8px 0 #fff, 0 -8px #fff, 4px 4px #000,
    8px 4px #000, 12px 4px #000, 16px 4px #000, 12px 0 #000, 8px -4px #000,
    4px -8px #000, 0 -4px #fff;
}
.bubble.bottom::after {
  height: 4px;
  width: 4px;
  bottom: -8px;
  left: 32px;
  box-shadow: 0 4px #000, 0 8px #000, 0 12px #000, 0 16px #000, -4px 12px #000,
    -8px 8px #000, -12px 4px #000, -4px 4px #fff, -8px 4px #fff, -4px 8px #fff,
    -4px 0 #fff, -8px 0 #fff, -12px 0 #fff;
}
.bubble.left::after {
  height: 4px;
  width: 4px;
  top: 20px;
  left: -8px;
  background: white;
  box-shadow: -4px -4px #fff, -4px 0 #fff, -8px 0 #fff, 0 -8px #fff,
    -4px 4px #000, -8px 4px #000, -12px 4px #000, -16px 4px #000, -12px 0 #000,
    -8px -4px #000, -4px -8px #000, 0 -4px #fff;
}
